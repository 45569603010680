/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:b31f0128-cc74-4ded-9dbd-d04db531f84e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_nqiCRF7KF",
    "aws_user_pools_web_client_id": "66mprhklv4n42hki9pk77kjk15",
    "oauth": {}
};


export default awsmobile;
